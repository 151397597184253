import { Box, BoxProps, TextField, TextFieldProps } from "@mui/material";
import { useField } from "remix-validated-form";
import { Small } from "./Typography";

export interface BazaarTextFieldProps {}

const BazaarTextField: React.FC<TextFieldProps & BoxProps> = ({
  name,
  label,
  InputProps,
  ...props
}: any) => {
  const boxProps: any = {};
  const textFieldProps: any = {};
  const { getInputProps, error } = useField(name);

  for (const key in props) {
    if (spacePropList.includes(key)) {
      boxProps[key] = props[key];
    } else textFieldProps[key] = props[key];
  }

  return (
    <Box {...boxProps}>
      {label && (
        <Small
          display="block"
          mb={1}
          textAlign="left"
          fontWeight="400"
          color="grey.700"
        >
          {label}
        </Small>
      )}

      <TextField
        error={error !== undefined}
        helperText={error}
        {...getInputProps({
          id: name,
        })}
        InputProps={{
          ...InputProps,
          style: {
            ...InputProps?.style,
            // height: 44,
          },
        }}
        {...textFieldProps}
      />
    </Box>
  );
};

const spacePropList = [
  "m",
  "mt",
  "mr",
  "mb",
  "ml",
  "mx",
  "my",
  "p",
  "pt",
  "pr",
  "pb",
  "pl",
  "px",
  "py",
  "margin",
  "marginTop",
  "marginRight",
  "marginBottom",
  "marginLeft",
  "marginX",
  "marginY",
  "padding",
  "paddingTop",
  "paddingRight",
  "paddingBottom",
  "paddingLeft",
  "paddingX",
  "paddingY",
  "width",
];

export default BazaarTextField;
